import { DateTime } from 'luxon';

const config = {
    // basename: only at build time to set, and don't add '/' at end off BASENAME for breadcrumbs, also don't put only '/' use blank('') instead,
    // like '/berry-material-react/react/default'
    appVersion: process.env.REACT_APP_VERSION,
    basename: '/stage',
    // defaultPath: '/PMP-LJAV/Bhaugolik',
    defaultPath: '/dashboard/default',
    fontFamily: `'Sumana', 'Karma', 'Laila', 'Poppins', 'Sahitya', 'Roboto', sans-serif`,
    borderRadius: 12,
    outlinedFilled: true,
    theme: 'light', // light, dark
    presetColor: process.env.REACT_APP_ENVIRONMENT === 'Production' ? 'default' : 'theme6', // default, theme1, theme2, theme3, theme4, theme5, theme6
    // 'en' - English, 'fr' - French, 'ro' - Romanian, 'zh' - Chinese
    i18n: 'en',
    rtlLayout: false,
    jwt: {
        secret: 'SECRET-KEY',
        timeout: '1 days'
    },
    firebase: {
        apiKey: process.env.REACT_APP_API_KEY,
        authDomain: process.env.REACT_APP_AUTH_DOMAIN,
        projectId: process.env.REACT_APP_PROJECT_ID,
        storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
        messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
        appId: process.env.REACT_APP_APP_ID,
        measurementId: process.env.REACT_APP_MEASUREMENT_ID
    },
    auth0: {
        client_id: 'HvYn25WaEHb7v5PBT7cTYe98XATStX3r',
        domain: 'demo-localhost.us.auth0.com'
    }
};

export const fetchMonthOptions = () => {
    const MONTH_FORMAT = 'MMMM';
    const months = [];
    const dt = DateTime.now();
    if (dt.month >= 4 && dt.month <= 12) {
        const monthCounter = dt.month - 3;
        for (let i = 0; i < monthCounter; i += 1) {
            const curMonth = dt.minus({ months: i });
            months.push(curMonth.setLocale('mr').toFormat(MONTH_FORMAT));
        }
    } else {
        const counter = dt.month + 8;
        for (let i = 1; i <= counter; i += 1) {
            const curMonth = dt.minus({ months: i });
            months.push(curMonth.setLocale('mr').toFormat(MONTH_FORMAT));
        }
    }
    return months;
};

export default config;
